<template>
  <div class="pt-4">
    <v-container fluid class="px-15">
      <v-row v-if="!isLoading" justify="start" align="start" class="ma-0 mt-5">
        <div>
          <v-col cols="12" class="pa-0">
            <div class="headline-medium text-primary">
              {{ texts.conversionsHistory.title }}
            </div>
          </v-col>
          <v-col cols="12" class="pa-0">
            <div class="body-large text-secondary">
              {{ texts.conversionsHistory.subTitle }}
            </div>
          </v-col>
        </div>
      <v-spacer></v-spacer>
      <v-card v-if="!isLoading" class="card-main mx-3 my-3" style="border: 1px solid #C5CDD3; height: fit-content;" width="350px" elevation="0" outlined>
        <v-card-text class="pt-6">
          <v-row align="center">
            <v-col cols="9" class="pl-5">
              <div class="usd-balance">{{ texts.activeForwards.usdBalance }}</div>
              <div class="content-balance">
                ${{ formatMoney(balanceUsd ? balanceUsd : 0) }} USD
              </div>
            </v-col>
            <v-col cols="3" class="text-right pr-5">
              <v-img
                src="../../assets/images/Flags_usa.png"
                alt="usa-flag"
                width="50"
                height="30"
              />
            </v-col>
          </v-row>
          <div class="pl-3 pt-2 blocked-amount">
            {{ texts.activeForwards.blockedAmount }}
            <span class="blocked-amount-2">${{ formatMoney(blockedUsd ? blockedUsd : 0) }} USD</span>
          </div>
        </v-card-text>
      </v-card>
      <v-skeleton-loader
        v-else
        class="mx-3 my-3"
        height="200px"
        width="400px"
        type="image"
      ></v-skeleton-loader>

    </v-row>
    <v-card v-if="!isLoading" class="rounded-xl elevation-5 mt-5">
      <v-toolbar dense dark color="#142e53" elevation="2">
        <v-toolbar-title>{{ texts.conversionsHistory.title }}</v-toolbar-title> 
        <v-spacer></v-spacer>
        <v-btn text small @click="dataXLSX()">
          <h4 style="font-family: 'montserrat-bold';">{{ texts.conversionsHistory.download }}</h4>
          <v-icon right>mdi-microsoft-excel</v-icon>
        </v-btn>
     </v-toolbar>
      <v-spacer></v-spacer>
      <v-card-title style="justify-content: flex-end;">
        <div style="background: #FFFFFF; box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15); border-radius: 10px; padding: 15px; border: 1px solid #C5CDD3;">
          {{ texts.conversionsHistory.totalCurrencyGain }}
          <span class="ml-2" style="font-weight: bold;" :style="{ color: totalProfit < 0 ? 'red' : 'green'}"> 
            {{ " $" + formatMoney(totalProfit) + " MXN" }}
          </span>
        </div>
      </v-card-title>
      <v-card-text>
        <v-spacer></v-spacer>
        <v-data-table fixed-header :height="'40vh'" :headers="headersForex" :items="items">
          <template v-slot:item.sTransactionType="{ item }">
            <v-chip
              v-if="item.sTransactionType == 'b80e8dc6-4138-449e-8903-968efb8437b3'" class="ma-2"
              color="red"
              outlined
            >
              {{ "Forward" }}
            </v-chip>
            <v-chip
              v-else-if="
                item.sTransactionType == 'e7b157eb-8dcb-4655-8d0e-4eb4e7e9270a'
              "
              class="ma-2"
              color="blue"
              outlined
            >
              {{ "Forex" }}
            </v-chip>
            <v-chip
              v-else-if="
                item.sTransactionType == '1e337607-b37b-4491-83b0-6383a45f9481'
              "
              class="ma-2"
              color="green"
              outlined
            >
              {{ "Withdrawal" }}
            </v-chip>
            <v-chip
              v-else-if="
                item.sTransactionType == 'f588edfa-73d4-4365-a240-90a3339dfdb2'
              "
              class="ma-2"
              color="purple"
              outlined
            >
              {{ "Collect" }}
            </v-chip>
            <v-chip
              v-else-if="
                item.sTransactionType == 'ea9d350f-f00b-4763-90db-72e7af54dc73'
              "
              class="ma-2"
              color="orange"
              outlined
            >
              {{ "Pay Now" }}
            </v-chip>
          </template>
          <template v-slot:item.sTargetAccount="{ item }">
            <div v-if="item.payment.length > 0">
              <h4 v-if="item.payment[0].payment.payment_recipient.sEntityBusiness == 'OWN COMPANY'" style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif">
                {{ item.payment[0].payment.payment_recipient.sBankName + " - *" + item.payment[0].payment.payment_recipient.sAccountNumber.slice(-4) }}
              </h4>
              <h4 v-if="item.payment[0].payment.payment_recipient.sEntityBusiness == 'COMPANY'" style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif">
                {{ item.payment[0].payment.payment_recipient.sName + " - *" + item.payment[0].payment.payment_recipient.sAccountNumber.slice(-4) }}
              </h4>
            </div>
            <h4 v-else style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif">
              {{ "Wallet MXN" }}
            </h4>
          </template>
          <template v-slot:item.dAmount="{ item }">
            <h4
              style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif"
            >
              $ {{ formatMoney(item.dAmount) }} USD
            </h4>
          </template>
          <template v-slot:item.iTrackingId="{ item }">
            <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif">
              {{ item.iTrackingId }}
            </h4>
          </template>
          <template v-slot:item.created_at="{ item }">
            <h4
              style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif"
            >
              {{ formatDate(item.created_at) }}
            </h4>
          </template>

          <template v-slot:item.dDesAmount="{ item }">
            <h4
              style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif"
            >
              $ {{ formatMoney(item.dDesAmount) }} MXN
            </h4>
          </template>
          <template v-slot:item.dExchangeRate="{ item }">
            <h4
              style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif"
            >
              $ {{ formatMoney(item.dExchangeRate) }} MXN
            </h4>
          </template>
          
          <template v-slot:item.client="{ item }">
            <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif;"> {{ item.invoiceMX.length > 0 ? item.invoiceMX[0].clientsRecipient[0].sName : "N/A" }}</h4>
          </template>
          <template v-slot:item.invoice="{ item }">
            <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif;"> {{ item.invoiceMX.length > 0 ? item.invoiceMX[0].sInvoice : "N/A" }}</h4>
          </template>
          <template v-slot:item.purchaseDate="{ item }">
            <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif;"> {{ item.invoiceMX.length > 0 ? formatDate(item.invoiceMX[0].dPurchaseDate) : "N/A"}}  </h4>
          </template>
          <template v-slot:item.percentProfit="{ item }">
            <h4
              v-if="item.percentProfit"
              :style="{ color: item.percentProfit < 0 ? 'red' : 'green' }"
              style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif"
            >
              {{
                item.percentProfit
                  ? formatMoney(item.percentProfit) + "%"
                  : "N/A"
              }}
            </h4>
            <h4 v-else>{{ "N/A" }}</h4>
          </template>
          <template v-slot:item.dollarProfit="{ item }">
            <h4
              v-if="item.dollarProfit"
              :style="{ color: item.dollarProfit < 0 ? 'red' : 'green' }"
              style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif"
            >
              {{
                item.dollarProfit
                  ? "$ " + formatMoney(item.dollarProfit) + " MXN"
                  : ""
              }}
            </h4>
            <h4 v-else>{{ "N/A" }}</h4>
          </template>
          <template v-slot:item.actions="{ item }">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="openDialog(item)" :disabled="true" v-bind="attrs" v-on="on" icon color="black">
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>{{ texts.conversionsHistory.viewDetail }}</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-skeleton-loader
      v-else
      class="mx-3 my-3"
      height="500px"
      type="image"
    ></v-skeleton-loader>
    </v-container>
    <v-dialog v-model="dialogDetailConversion" max-width="1000">
      <v-card v-if="!loadingTransaction"> 
        <v-card-title class="pa-0" style="display: flex; text-align: center; justify-content: center;">
          <h3 class="font-weight-bold my-4">
            {{ texts.conversionsHistory.detail }}
          </h3>
        </v-card-title>
        <v-card-text class="pb-5 px-10">
          <div class="my-8"  style="display: flex; justify-content: space-around; flex-direction: column;">
            <div style="display: flex; justify-content: space-between;">
              <div>
                <div style="color: black; font-size: 20px;">
                {{ texts.conversionsHistory.type }}
                </div>
                <div style="color: black; font-size: 22px; font-weight: bold;">
                  {{ conversion.sTransactionType == 'e7b157eb-8dcb-4655-8d0e-4eb4e7e9270a' ? "Forex" : "Forward" }}
                </div>
              </div>
              <div>
                <div style="color: black; font-size: 20px; text-align: center;">
                {{ texts.conversionsHistory.amount }}
                </div>
                <div style="color: black; font-size: 22px; font-weight: bold;">
                  {{ "$ " + formatMoney(conversion.dDesAmount) + " MXN" }}
                </div>
              </div>
              <!-- <template v-if="conversion.invoice">
                <div v-if="conversion.invoice.length > 0">
                  <div style="color: black; font-size: 20px;">
                    Profit
                  </div>
                  <div style="color: green; font-size: 22px; font-weight: bold;">
                    {{ formatMoney(conversion.percentProfit) + "%"  }}
                  </div>
                  <div style="color: green; font-size: 22px; font-weight: bold;">
                    {{ formatMoney(conversion.dollarProfit) + " USD"  }}
                  </div>
                </div>
                <div v-else></div>
              </template> -->
            </div>
          </div>
          <div class="my-8" style="display: flex; justify-content: space-around; flex-direction: column;">
            <div style="display: flex; justify-content: space-between;">
              <div>
                <div style="color: black; font-size: 20px;">
                {{ texts.conversionsHistory.exchangeRate }} <span style="color: black; font-size: 22px; font-weight: bold;"> {{ "$ " + conversion.dExchangeRate + " MXN" }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="my-8" style="display: flex; justify-content: space-around; flex-direction: column;">
            <div style="display: flex; justify-content: space-between;">
              <div>
                <div style="color: black; font-size: 20px;">
                {{ texts.conversionsHistory.creationDate }} <span style="color: black; font-size: 22px; font-weight: bold;"> {{ formatDate(conversion.created_at) }}</span>
                </div>
              </div>
              <div>
                <div style="color: black; font-size: 20px;" v-if="conversion.dForwardTransactionDate">
                {{ texts.conversionsHistory.completionDate }} <span style="color: black; font-size: 22px; font-weight: bold;"> {{ formatDate(conversion.dForwardTransactionDate) }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-8">
            <div class="mb-4" style="color: black; font-size: 22px; font-weight: bold;">{{ texts.conversionsHistory.percentageConversionPaid }}</div>
            <v-progress-linear
              height="45"
              :value="conversion.percentPay"
            >
              <strong style="color: white;">{{ formatMoney(conversion.percentPay) }}%</strong>
            </v-progress-linear>
          </div>
          <div>
            <div class="mb-6" style="display: flex; justify-content: space-around; color: black; font-size: 22px; font-weight: bold;"> 
              <div>{{ texts.conversionsHistory.payments }}</div>
              <v-spacer></v-spacer>
            </div>
            <div v-if="conversion.payment">
              <div v-if="conversion.payment.length > 0" style="display: flex; justify-content: flex-start; gap: 30px">
                <v-card class="mb-4 mx-4" style="width: 340px;" v-for="(item, idx) in conversion.payment" :key="idx">
                  <v-card-text>
                    <div class="mt-0"  style="display: flex; justify-content: space-around; flex-direction: column;">
                      <div style="display: flex; justify-content: space-between; align-items: center;  align-items: flex-start; flex-direction: column;">
                        <div class="my-2">
                          <div style="color: black; font-size: 20px;">
                            {{ texts.conversionsHistory.amount }}
                          </div>
                          <div style="color: black; font-size: 22px; font-weight: bold;">
                            {{ "$ " + formatMoney(item.dAmount) + "MXN" }}
                          </div>
                        </div>
                        <div class="my-2">
                          <div style="color: black; font-size: 20px;">
                          {{ texts.conversionsHistory.paymentDate }}
                          </div>
                          <div style="color: black; font-size: 22px; font-weight: bold;">
                            {{ formatDate(item.created_at) }}
                          </div>
                        </div>
                        <div class="my-2" v-if="item.payment.payment_recipient">
                          <div style="color: black; font-size: 20px;">
                          {{ texts.conversionsHistory.recipient }}
                          </div>
                          <div style="color: black; font-size: 22px; font-weight: bold;">
                            {{ item.payment.payment_recipient.sName }}
                          </div>
                        </div>
                        <div class="my-2" v-if="item.payment.invoice_payments_relationship.length > 0">
                          <div style="color: black; font-size: 20px;">
                          {{ texts.conversionsHistory.invoice }}
                          </div>
                          <div style="color: black; font-size: 22px; font-weight: bold;" >
                            {{ item.payment.invoice_payments_relationship[0].invoice.sInvoice }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <v-card v-else class="py-8" style="width: 100%; text-align: center;">
                <v-card-text>
                  <h4>{{ texts.conversionsHistory.noPayments }}</h4>
                </v-card-text>
              </v-card>
            </div>
          </div>
          <div class="mb-8 mt-4">
            <div class="mb-4" style="color: black; font-size: 22px; font-weight: bold;">{{ texts.conversionsHistory.percentageConversionUsed }}</div>
            <v-progress-linear
              height="45"
              :value="conversion.percent"
            >
              <strong style="color: white;">{{ formatMoney(conversion.percent) }}%</strong>
            </v-progress-linear>
          </div>
          <div>
            <div class="mb-6" style="display: flex; justify-content: space-around; color: black; font-size: 22px; font-weight: bold;"> 
              <div>{{ texts.conversionsHistory.invoicesAssigned }}</div>
              <v-spacer></v-spacer>
            </div>
            <div v-if="conversion.invoice">
              <div v-if="conversion.invoice.length > 0" style="display: flex; justify-content: flex-start; gap: 30px">
                <v-card class="mb-4 mx-4" style="width: 346px;" v-for="(item, idx) in conversion.invoice" :key="idx">
                  <v-card-title>
                    <h4>{{ texts.conversionsHistory.invoice }} {{ item.invoice.sInvoice }}</h4>
                    <v-spacer></v-spacer>
                    <div v-if="item.payment">
                      <v-chip v-if="item.dAmount == item.payment.dDescAmount"
                        color="blue"
                        outlined
                      >{{ "Pagada" }}</v-chip>
                    </div>
                    <v-chip v-else
                      color="red"
                      class="ml-3"
                      outlined
                    >{{ "Pendiente pago" }}</v-chip>
                  </v-card-title>
                  <v-card-text>
                    <div class="mt-2"  style="display: flex; justify-content: space-around; flex-direction: column;">
                      <div style="display: flex; justify-content: space-between; align-items: center;  align-items: flex-start; flex-direction: column;">
                        <div class="my-2">
                          <div style="color: black; font-size: 20px;">
                            {{ texts.conversionsHistory.amount }}
                          </div>
                          <div style="color: black; font-size: 22px; font-weight: bold;">
                            {{ "$ " + formatMoney(item.invoice.dTotalAmount) + "MXN" }}
                          </div>
                        </div>
                        <div class="my-2">
                          <div style="color: black; font-size: 20px;">
                          {{ texts.conversionsHistory.purchaseDate }}
                          </div>
                          <div style="color: black; font-size: 22px; font-weight: bold;">
                            {{ formatDate(item.invoice.dPurchaseDate) }}
                          </div>
                        </div>
                        <div class="my-2">
                          <div style="color: black; font-size: 20px; text-align: center;">
                            {{ texts.conversionsHistory.exchangeRateProtected }}
                          </div>
                          <div style="color: black; font-size: 22px; font-weight: bold;">
                            {{ "$ " + formatMoney(item.invoice.dRateProtection) + " MXN" }}
                          </div>
                        </div>
                        <div class="my-2" style="display: flex; width: 100%; justify-content: space-around;">
                          <div>
                            <div class="mb-2" style="color: black; font-size: 20px;">
                              {{ texts.conversionsHistory.profitPercent }}
                            </div>
                            <div :style="{ color: item.invoice.dProfitPercentage < 0 ? 'red' : 'green'}" style="color: black; font-size: 22px; font-weight: bold;">
                              {{ item.invoice.dProfitPercentage + "%" }}
                            </div>
                          </div>
                          <div>
                            <div class="mb-2" style="color: black; font-size: 20px;">
                            {{ texts.conversionsHistory.profit }}
                            </div>
                            <div :style="{ color: item.invoice.dProfitPercentage < 0 ? 'red' : 'green'}"style="color: black; font-size: 22px; font-weight: bold;">
                              {{ "$ " + item.invoice.dCurrencyGain + " USD" }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <v-card v-else class="py-8" style="width: 100%; text-align: center;">
                <v-card-text>
                  <h4>{{ texts.conversionsHistory.noInvoicesAssigned }}</h4>
                </v-card-text>
              </v-card>
            </div>
          </div>
          <div style="display: flex; justify-content: end; margin-top: 20px">
            <v-btn
              elevation="0"
              class="button-cancel mon-regular"
              @click="cancel()"
            >
              {{ texts.modalForm.cancel }}
            </v-btn>
            <v-spacer></v-spacer>
          </div>
        </v-card-text>
      </v-card>
      <v-skeleton-loader
        v-else
        class="mx-auto"
        type="image"
      ></v-skeleton-loader>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

export default {
  name: "DashboardView",
  data() {
    return {
      nameXLSX: "HistorialConversiones.xlsx",
      texts: "",
      drawer: null,
      dialogDetailConversion: false,
      conversion: {},
      actualCurrency: "0",
      actualCurrencyMxn: "0",
      referenceCurrency: "0",
      isLoading: false,
      isRegisterEfex: true,
      exchangeRateData: [],
      selected: [],
      exchangeRateDataPaid: [],
      exchangeRateDataSeparate: [],
      balanceMxn: "0",
      balanceUsd: "0",
      balance: "0",
      items: [],
      tab: 0,
      contacts: [],
      itemsSelected: [],
      itemsSelectedForwards: [],
      isForwardRateCalculated: false,
      bSelectedAll: false,
      dialogAddFoundss: false,
      dialogSendPay: false,
      dialogAddFounds: false,
      dataAddFounds: {},
      dialogCreate: false,
      dialogPay: false,
      editableData: {},
      selectedCard: null,
      totalUsd: 0,
      selectedMenuItem: "dashboard",
      contactData: {
        sName: "",
        sEmail: "",
        sBank_name: "",
        sAccount_number: "",
        sEntity_business: "COMPANY"
      },
      isForwardsActive: false,
      showCreateProvider: false,
      isResponsive: false,
      screenWidth: 0,
      bLoading: false,
      isDeferredPayment: false,
      stepper: false,
      e1: 1,
      timerCount: 30,
      headersForex: [
        this.selectLanguage === 'en' ? { text: "Id", value: "iTrackingId", align: "center", sortable: false} : { text: "Id", value: "iTrackingId", align: "center", sortable: false},
        this.selectLanguage === 'en' ? { text: "Creation date", value: "created_at", align: "center"} : { text: "Creation date", value: "created_at", align: "center"},
        this.selectLanguage === 'en' ? { text: "Type transaction", value: "sTransactionType", align: "center"} : { text: "Type transaction", value: "sTransactionType", align: "center"},
        this.selectLanguage === 'en' ? { text: "Type transaction", value: "sTargetAccount", align: "center"} : { text: "Type transaction", value: "sTargetAccount", align: "center"},
        this.selectLanguage === 'en' ? { text: "Total amount USD", value: "dAmount", align: "center"} : { text: "Total amount USD", value: "dAmount", align: "center"},
        this.selectLanguage === 'en' ? { text: "Total amount MXN", value: "dDesAmount", align: "center"} : { text: "Total amount MXN", value: "dDesAmount", align: "center"},
        this.selectLanguage === 'en' ? { text: "Exchange rate", value: "dExchangeRate", align: "center"} : { text: "Exchange rate", value: "dExchangeRate", align: "center"},
        this.selectLanguage === 'en' ? { text: "Client", value: "client", align: "center", sortable: false} : { text: "Client", value: "client", align: "center", sortable: false},
        this.selectLanguage === 'en' ? { text: "Invoice", value: "invoice", align: "center", sortable: false} : { text: "Invoice", value: "invoice", align: "center", sortable: false},
        this.selectLanguage === 'en' ? { text: "Purchase date", value: "purchaseDate", align: "center", sortable: false} : { text: "Purchase date", value: "purchaseDate", align: "center", sortable: false},
        this.selectLanguage === 'en' ? { text: "Currency profit (%)", value: "percentProfit", align: "center", sortable: false} : { text: "Currency profit (%)", value: "percentProfit", align: "center", sortable: false},
        this.selectLanguage === 'en' ? { text: "Currency gain", value: "dollarProfit", align: "center", sortable: false} : { text: "Currency gain", value: "dollarProfit", align: "center", sortable: false},
        this.selectLanguage === 'en' ? { text: "Actions", value: "actions", align: "center", sortable: false} : { text: "Actions", value: "actions", align: "center", sortable: false},
       ],
      timerId: null,
      totalProfit: "",
      dataEfex: {},
      exchangeRateAdjustments: [],
      exchangeRatesStateForwards: [],
      isPayMxn: false,
      bSelectedAllSeparate: false,
      dialogEditProfileActive: false,
      isForwards: false,
      isForwardsPayment: false,
      userName: "",
      modalCurrency: 0,
      loadingTransaction: false,
      miniVariant: true,
      expandOnHover: false,
      transactions: [],
      invoices: []
    };
  },
  methods: {
    openDialog(item) {
      this.dialogDetailConversion = true;
      this.getConversionsWithId(item)
    },
    getConversionsWithId(item) {
      this.loadingTransaction = true;
      this.conversion = [];

      const payload = {
        sTransactionId: item.sTransactionId,
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/transactions/detail`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }
      )
        .then((response) => {
          this.conversionWithId = response.data.transactions.map(transaction => {
            let totalAmount = 0;
            let totalAmountPay = 0;
            if (transaction.invoice.length > 0) {
              transaction.invoice.map(invoice => {
                totalAmount += parseFloat(invoice.invoice.dTotalAmount);
              });
            }
            if (transaction.payment.length > 0) {
              transaction.payment.map(payment => {
                totalAmountPay += parseFloat(payment.payment.dDescAmount);
              });
            }
            transaction.totalAmount = totalAmount;
            transaction.totalAmountPay = totalAmountPay;

            transaction.percent = (transaction.totalAmount / transaction.dDesAmount) * 100
            transaction.percentPay = (transaction.totalAmountPay / transaction.dDesAmount) * 100
            return transaction;
          });

          this.conversion = this.conversionWithId[0];

          if (this.conversion.invoice.length > 0) {
            this.sPaymentRecipientId = this.conversion.invoice[0].invoice.paymentsRecipent[0].sPaymentRecipientId
          } else {
            this.sPaymentRecipientId = null;
          }
          this.loadingTransaction = false;
        })
        .catch((error) => {
          this.loadingTransaction = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    cancel() {
      this.dialogDetailConversion = false;
    },
    getPaymentsHistory() {
      this.isLoading = true;
      DB.get(`${URI}/api/v1/${this.selectLanguage}/transactions/historyMX`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : ""
        }
      }).then((response) => {
        this.totalProfit = response.data.totalProfit;
        this.balanceUsd = response.data.balance.balanceUsd.balance;
        this.blockedUsd = response.data.balance.blocked.balance;
        this.items = response.data.transactions.map((transaction) => {
          let invoices = []
          let purchaseDate = []
          if (transaction.invoice.length > 0) {
            transaction.invoice.map((invoice) => {
              invoices.push(invoice.invoice.sInvoice)
              purchaseDate.push(this.formatDate(invoice.invoice.dPurchaseDate))
            });
          }
          transaction.invoice = invoices.join(', ');
          transaction.purchaseDate = purchaseDate.join(', ');
          return transaction;
        });
        this.isLoading = false;
      }).catch((error) => {
          // this.isLoading = false;
          if (error.response.data.code) {
            this.$router.push("/welcome").catch(() => {});
          }
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    formatMoney: function (value) {
      let multiplied = value * 100;
      let rounded = Math.round(multiplied);
      let formattedNumber = rounded / 100;
      return new Intl.NumberFormat("en-US", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(formattedNumber);
    },
    formatDate: function (date) {
      if (this.selectLanguage === "sp") {
        moment.locale("es");
      } else {
        moment.locale("en");
      }
      let newDate = moment(date);
      let formattedDate = newDate.format("MMMM D, YYYY");
      return formattedDate;
    },
    dataXLSX() {
      const transactionTypeMap = {
        'b80e8dc6-4138-449e-8903-968efb8437b3': 'Forward',
        'e7b157eb-8dcb-4655-8d0e-4eb4e7e9270a': 'Forex',
        '1e337607-b37b-4491-83b0-6383a45f9481': 'Withdrawal',
        'f588edfa-73d4-4365-a240-90a3339dfdb2': 'Collect',
        'ea9d350f-f00b-4763-90db-72e7af54dc73': 'Pay Now'
      };

      try {
        let data = this.items.map((item) => {
          return {
            Id: item.iTrackingId ? item.iTrackingId : "",
            "Fecha de creación": item.created_at ? this.formatDate(item.created_at) : "",
            "Type Transaction": transactionTypeMap[item.sTransactionType] || 'N/A',
            "Target Account": item.payment.length > 0 
              ? (() => {
                  const recipient = item.payment[0].payment.payment_recipient;
                  const accountNumber = "*" + recipient.sAccountNumber.slice(-4);
                  return recipient.sEntityBusiness === 'OWN COMPANY' 
                    ? `${recipient.sBankName} - ${accountNumber}` 
                    : `${recipient.sName} - ${accountNumber}`;
                })() 
              : "Wallet MXN",
            "Total amount (USD)": item.dAmount ? parseFloat(item.dAmount) : "",
            "Total amount (MXN)": item.dDesAmount ? parseFloat(item.dDesAmount) : "",
            "Exchange rate": item.dExchangeRate ? item.dExchangeRate : "",
            Client: item.invoiceMX.length > 0 ? item.invoiceMX[0].clientsRecipient[0].sName : "",
            Invoice: item.invoiceMX.length > 0 ? item.invoiceMX[0].sInvoice : "",
            "Purchase Date": item.invoiceMX.length > 0 ? this.formatDate(item.invoiceMX[0].created_at) : "",
            "Currency profit (%)": item.percentProfit ? parseFloat(item.percentProfit) : "",
            "Currency gain (USD)": item.dollarProfit ? parseFloat(item.dollarProfit) : "",
          };
        });
        const worksheet = XLSX.utils.json_to_sheet(data);

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Hoja1");

        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

        const blob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

        saveAs(blob, this.nameXLSX);
        this.success = true;
        this.msgSuccess = "Descargando documento";
      } catch (error) {
        this.error = true;
        this.codeError = "CSV_NO_DATA";
      }
    },
    updateHeaders() {
      this.headersForex = [
        { text: this.texts.conversions.id, value: "iTrackingId", align: "center" },
        { text: this.texts.conversions.transactionDate, value: "created_at", align: "center" },
        { text: this.texts.conversions.typeTransaction, value: "sTransactionType", align: "center" },
        { text: this.texts.conversions.targetAccount, value: "sTargetAccount", align: "center" },
        { text: this.texts.conversions.totalAmountUSD, value: "dAmount", align: "center" },
        { text: this.texts.conversions.totalAmountMXN, value: "dDesAmount", align: "center" },
        { text: this.texts.conversions.exchangeRate, value: "dExchangeRate", align: "center" },
        { text: this.texts.conversions.client, value: "client", align: "center", sortable: false },
        { text: this.texts.conversions.invoice, value: "invoice", align: "center", sortable: false },
        { text: this.texts.conversions.purchaseDate, value: "purchaseDate", align: "center", sortable: false},
        { text: this.texts.conversions.currencyProfit, value: "percentProfit", align: "center", sortable: false},
        { text: this.texts.conversions.dollarProfit, value: "dollarProfit", align: "center"},
        { text: this.texts.conversions.actions, value: "actions", align: "center"}
      ]
    }
  },
  beforeMount() {
    this.texts = FILE.takeRate[this.selectLanguage];
    this.getPaymentsHistory();
    this.updateHeaders();
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    }
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.takeRate[this.selectLanguage];
      }
      this.updateHeaders();
    }
  }
};
</script>

<style scoped>
.bg-container {
  width: 100%;
  background: #fff;
  opacity: 1;
  height: 100%;
}
.full-height {
  height: 100vh;
  width: 100%;
  overflow-y: auto;
}
.active {
  background-color: #f0f0f0;
}
/* .active-section .v-list-item__icon {
  color: yellow !important;
  background-color: yellow !important;
}

.active-section .v-list-item__title {
  background-color: yellow !important;
} */

.active-item {
  background-color: #f5f7f9 !important;
}
.button-add {
  background: transparent linear-gradient(258deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  text-transform: initial;
}
.v-tabs-slider {
  background: #f0be43 !important;
  height: 3px;
  color: #f0be43 !important;
  border-radius: 100px;
}

.card-main {
  width: 40vh;
  height: 16vh;
  position: relative;
  background: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  overflow: hidden;
}

.card-content {
  position: absolute;
  top: 19px;
  left: 16px;
}

.circle-content {
  text-align: center;
  color: white;
  margin: auto;
}

.btn-custom {
  color: #f0be43;
}

.card-title {
  color: #302e2e;
  font-size: 16px;
  font-family: montserrat-bold;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 8px;
}

.card-info,
.balance-title {
  color: #777777;
  font-size: 10px;
  font-weight: 400;
  line-height: 20px;
}

.card-mxn {
  width: 200px;
  color: #f0be43;
  font-size: 24px;
  font-family: Mons;
  font-weight: 700;
  line-height: 36px;
  font-style: normal;
  word-wrap: break-word;
}

.container-width,
.header-container {
  max-width: 1280px;
  margin: auto;
}

.title-medium {
  font-family: "montserrat-semibold";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.headline-medium {
  font-family: "montserrat-semibold";
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
}

.body-large {
  font-family: "montserrat-regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.blocked-amount {
  font-family: "montserrat-regular";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.blocked-amount-2 {
  color: #302e2e;
  font-family: "montserrat-regular";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}

.circle-sheet {
  width: 200px;
  height: 200px;
  padding-left: 17px;
  padding-right: 62px;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: absolute;
  right: -60px;
  top: -20px;
}

.usd-balance {
  color: #666565;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.content-balance {
  color: #302e2e;
  font-family: "montserrat-medium", sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  padding-top: 10px;
}

.h5 {
  font-family: "montserrat-bold";
  font-size: 24px;
  line-height: 36px;
  text-align: left;
}

.buttonAddFounds {
  text-align: center;
  color: #f0be43;
  font-size: 16px;
  font-family: "montserrat-semibold";
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;
  word-wrap: break-word;
}

.modalTextAddFounds {
  color: #302e2e;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.table th {
  background-color: #f2f2f4;
  /* border: 1px solid #ccc; */
}

@media (max-width: 1150px) {
  .card-title {
    font-size: 14px;
  }

  .card-mxn {
    font-size: 20px;
  }

  .usd-balance {
    font-size: 12px;
  }

  .content-balance {
    font-size: 18px;
  }

  .blocked-amount {
    font-size: 12px;
  }

  .blocked-amount-2 {
    font-size: 14px;
  }

  .buttonAddFounds {
    font-size: 14px;
  }

  .circle-sheet {
    display: none;
  }
}

@media (max-width: 1250px) {
  .circle-sheet {
    width: 155px;
    height: 170px;
    padding-left: 17px;
    padding-right: 40px;
    right: -50px;
    top: -5px;
  }
}
</style>
